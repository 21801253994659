import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useDispatch, useSelector } from "react-redux";
import "../job/job.scss";
import editsvg from "../../assets/img/svg/edit.svg";
import editSelected from "../../assets/img/svg/editSelected.svg";
import copysvg from "../../assets/img/svg/copy.svg";
import downloadsvg from "../../assets/img/svg/download.svg";
import sharesvg from "../../assets/img/svg/share.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import JobTitle from "../../assets/img/svg/JobTitle.svg";
import ToneofVoice from "../../assets/img/svg/ToneofVoice.svg";
import Neuromarketing from "../../assets/img/svg/Neuromarketing.svg";
import ElementsofValue from "../../assets/img/svg/ElementsofValue.svg";
import addsvg from "../../assets/img/svg/add.svg";
import sparksvg from "../../assets/img/svg/spark.svg";
import Loader from "../../components/Loader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Otherpopup from "./otherpopup";
import {
  editedVacancyTextData,
  getBeautifiedData,
  getSocialMediaTemplate,
  toneOfVoice,
} from "../../redux/action/chatbotAction";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Utils from "../../utils/reduxUtils";
import CancellationPopup from "../../common/CancellationPopup";
import {
  handleCopyContent,
  handleShareEmail,
  handleToneOfVoice,
} from "./helper";
import TestPdfTemplate from "./TestPdfTemplate";
import html2pdf from "html2pdf.js";
import UpdatedVacancyHeading from "./updatedVacancyHeading.json";

const UpdatedVacancy = () => {
  const { chatbotDataLoading, vacancyText, vacancyId } = useSelector(
    (state) => state?.jobDescriptionFileUploadReducer
  );

  const credits = vacancyText[0]?.credits;
  const vacancyTextData =
    vacancyText[0]?.vacancyText !== undefined &&
      vacancyText[0]?.vacancyText !== null
      ? vacancyText[0]?.vacancyText
      : vacancyText[0];

  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );

  const dispatch = useDispatch();

  const [customTone, setCustomTone] = useState("");
  const [openToneDialog, setOpenToneDialog] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [editedVacancyText, setEditedVacancyText] = useState(null);
  const [templateData, setTemplateData] = useState([]);
  const [pdfDownloadStatus, setPdfDownloadStatus] = useState("Finished"); // OnGoing or Finished
  const [isCancellationPopupOpen, setIsCancellationPopupOpen] = useState(false);
  const [isFunctionalityDisabled, setIsFunctionalityDisabled] = useState(false);
  const [criteria, setCriteria] = useState([]);
  const [employmentConditions, setEmploymentConditions] = useState([]);
  const [roleOverView, setRoleOverView] = useState({});
  const [companyOverView, setCompanyOverView] = useState({});
  const [fittingTraits, setFittingTraits] = useState([]);
  const [reasonToJoin, setReasonToJoin] = useState([]);
  const [trendingTitles, setTrendingTitles] = useState([]);
  const [trendingTitlesTooltipValue, setTrendingTitlesTooltipValue] =
    useState("");
  const [elementOfValues, setElementOfValues] = useState([]);
  const [elementOfTooltipValues, setElementOfTooltipValues] = useState({});
  const [neuromarketingValues, setNeuroMarketingValues] = useState([]);
  const [neuromarketingTooltipValues, setNeuromarketingTooltipValues] =
    useState([]);
  const [storeEditedData, setStoreEditedData] = useState(
    editedVacancyText !== null ? editedVacancyText : vacancyTextData
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const isEditFocus = useRef(null);
  const pdfRef = useRef();
  const copyStoryTellingRef = useRef(null);


  console.log("editedVacancyText", editedVacancyText);
  console.log("initialVacancyText", vacancyTextData);

  const open = Boolean(anchorEl);

  //Function that handles Tone Of Voice Button click 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //Function that handles Tone Of Voice Dropdown close
  const handleClose = () => {
    setAnchorEl(null);
    setOpenToneDialog(false);
    setCustomTone("");
  };

  //Function that handles opening of dialog box for others option selection in Tone Of Voice 
  const handleChange = (value) => {
    if (value === "others") {
      setOpenToneDialog(true);
    }
  };

  //handle Tone Of voice selection
  const handleToneOfVoiceSelection = (tov) => {
    // handleToneOfVoice(tov, vacancyTextData, toneOfVoice, dispatch, handleClose);
    handleToneOfVoice(tov, storeEditedData, toneOfVoice, dispatch, handleClose);
  };

  //Function that handles opening of popup when clicking on discard button
  const handleOpenPopup = () => {
    setIsCancellationPopupOpen(true);
  };

  //Function that handles closing of popup when selecting any option coming after clicking of discard button
  const handleClosePopup = () => {
    setIsCancellationPopupOpen(false);
  };

  //Function that runs when "Yes" option is selected from discard popup
  const handleOption1Click = () => {
    // Perform action for Option 1
    setEditedVacancyText(null);
    setIsEditEnabled(false);
    handleClosePopup();
  };

  //Function that runs when "No" option is selected from discard popup
  const handleOption2Click = () => {
    // Perform action for Option 2
    handleClosePopup();
  };

  //Check reasons to join is empty
  const isReasonsToJoinEmpty = () => {
    const isEmpty = reasonToJoin?.every((item) => item === "");
    return isEmpty;
  };

  //Check is Criteria Empty
  const isCriteriaEmpty = () => {
    const result = criteria?.map((item) => {
      const [key] = Object.entries(item)[0];
      return (
        editedVacancyText?.Criteria?.find(
          (item) => Object.keys(item)[0] === key
        )?.[key] || ""
      );
    });
    const isEmpty = result?.every((item) => item == "");
    return isEmpty;
  };

  //Check employment conditions empty
  const isEmploymentConditionsEmpty = () => {
    const result = employmentConditions?.map((item) => {
      const [key] = Object.entries(item)[0];
      return (
        editedVacancyText?.Employment_Condition?.find(
          (item) => Object.keys(item)[0] === key
        )?.[key] || ""
      );
    });
    const isEmpty = result?.every((item) => item == "");
    return isEmpty;
  };

  //Function that sets the format of complete vacancyText before performing copy operation of whole content
  const formatVacancyDataForClipboard = (data) => {
    const renameKeys = {
      Role_Details: "Role Details",
      Employment_Condition: "Employment Conditions",
      Job_Title: "Job Title",
      Storytelling: "Storytelling",
      Who_Are_We: "Who Are We",
      Trending_Titles: "Trending Titles",
      application_instructions:
        "Do you recognize yourself in the profile? This is how you can apply for this position",
      Criteria: "The Job Requirements",
      reasons_to_join: "Just a Few Reasons to Want to Work with Us",
      Tone_of_voice: "Tone of Voice",
      Elements_of_values: "Elements of Values",
      neuromarketing_principles: "Neuromarketing Principles",
      fitting_traits: "These Characteristics Suit You",
    };

    // Custom mapping for nested keys in Role_Details
    const nestedRenameKeys = {
      Role_Details: {
        Role_Overview: "Role Overview",
        Motivation_for_Role: "Motivation for Role",
        What_activities_can_you_expect_during_a_working_day: "Daily Activities",
      },
      Company_Overview: {
        Who_Are_We: "Who Are We",
      },
    };

    const formatValue = (value, nestedMapping = {}) => {
      if (typeof value === "string") {
        return value; // Return string as-is
      } else if (Array.isArray(value)) {
        if (value.length > 0 && typeof value[0] === "object") {
          // Format array of objects
          return value
            .map(
              (obj) =>
                `- ${Object.entries(obj)
                  .map(([k, v]) => `${k}: ${v}`)
                  .join(", ")}`
            )
            .join("\n");
        } else {
          // Format array of strings
          return value.map((item) => `- ${item}`).join("\n");
        }
      } else if (typeof value === "object" && value !== null) {
        // Format nested objects
        return Object.entries(value)
          .map(([key, val]) => {
            const nestedHeading = nestedMapping[key] || key; // Use nested renamed key if available
            return `- ${nestedHeading}: ${val}`;
          })
          .join("\n");
      }
      return JSON.stringify(value, null, 2); // Fallback for other types
    };

    return Object.entries(data)
      .map(([key, value]) => {
        const heading = renameKeys[key] || key; // Use renamed key if available
        const nestedMapping = nestedRenameKeys[key] || {}; // Get nested mappings for specific keys
        return `${heading}:\n${formatValue(value, nestedMapping)}\n`;
      })
      .join("\n");
  };

  //Function that handles copy to clipboard functionality of whole content
  const handleCopyToClipboard = () => {
    const formattedString = formatVacancyDataForClipboard(vacancyTextData);

    // Copy the string to clipboard
    navigator.clipboard
      .writeText(formattedString)
      .then(() => {
        toast.success("Text copied to clipboard!");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  //Function that handles the content change in edit mode for fields- Job Title, StoryTelling and application instructions
  const handleEditData = (key, value) => {
    setEditedVacancyText((prevstate) => {
      // Log previous state here
      return {
        ...prevstate,
        [key]: value,
      };
    });

    setStoreEditedData((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };

  //Function that handles the content change in edit mode for field- roleOverview
  const handleEditRoleDetailsData = (key, value) => {

    setEditedVacancyText((prevstate) => {
      // Log previous state here
      return {
        ...prevstate,
        Role_Details: {
          ...prevstate?.Role_Details,
          [key]: value,
        },
      };
    });

    setStoreEditedData((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };

  //Function that handles the content change in edit mode for field- companyOverview
  const handleEditCompanyOverviewData = (key, value) => {

    setEditedVacancyText((prevstate) => {
      // Log previous state here
      return {
        ...prevstate,
        Company_Overview: {
          ...prevstate?.Company_Overview,
          [key]: value,
        },
      };
    });

    setStoreEditedData((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };


  //Function that handles the content change in edit mode for fields- Fitting traits and reasons to join
  const handleFunctionEditData = (key, updatedValues) => {
    setEditedVacancyText((prevState) => {
      return {
        ...prevState,
        [key]: updatedValues, // Update the key with the array of updated values
      };
    });

    // Optionally update another state or store, e.g., setStoreEditedData
    setStoreEditedData((prevState) => {
      return {
        ...prevState,
        [key]: updatedValues,
      };
    });
  };

  //Function that handles the content change in edit mode for field- Criteria
  const handleCriteriaEditData = (key, updatedValue) => {

    setEditedVacancyText((prevText) => {
      const updatedCriteria = prevText.Criteria.map((item) => {
        const [currentKey] = Object.entries(item)[0];
        if (currentKey === key) {
          return { [currentKey]: updatedValue };
        }
        return item;
      });

      return {
        ...prevText,
        Criteria: updatedCriteria,
      };
    });

    setStoreEditedData((prevText) => {
      const updatedCriteria = prevText.Criteria.map((item) => {
        const [currentKey] = Object.entries(item)[0];
        if (currentKey === key) {
          return { [currentKey]: updatedValue };
        }
        return item;
      });

      return {
        ...prevText,
        Criteria: updatedCriteria,
      };
    });
  };

  //Function that handles the content change in edit mode for field- Employment Condition
  const handleEmploymentEditData = (key, updatedValue) => {

    setEditedVacancyText((prevText) => {
      const updatedCriteria = prevText.Employment_Condition.map((item) => {
        const [currentKey] = Object.entries(item)[0];
        if (currentKey === key) {
          return { [currentKey]: updatedValue };
        }
        return item;
      });

      return {
        ...prevText,
        Employment_Condition: updatedCriteria,
      };
    });

    setStoreEditedData((prevText) => {
      const updatedCriteria = prevText.Employment_Condition.map((item) => {
        const [currentKey] = Object.entries(item)[0];
        if (currentKey === key) {
          return { [currentKey]: updatedValue };
        }
        return item;
      });

      return {
        ...prevText,
        Employment_Condition: updatedCriteria,
      };
    });
  };

  //This useEffect checks that if credits are 0 then isFunctionalityDisabled state is set to true that is used to disable few functionalities of this page
  useEffect(() => {
    if (credits === 0) {
      setIsFunctionalityDisabled(true);
    }
  }, [credits]);


  //Save Edited Data
  const handleSubmit = () => {
    const payload = {
      id: vacancyId,
      vacancyText: editedVacancyText,
    };

    dispatch({
      type: Utils.ActionName.CHATBOTDATA,
      payload: editedVacancyText,
    });
    dispatch(editedVacancyTextData(payload, setEditedVacancyText, setIsEditEnabled))
    setStoreEditedData(editedVacancyText);
    setIsEditEnabled(false);
    // toast.success("Text Edited Successfully");
  };

  //handle toggle Edit Mode
  const handleEditMode = () => {
    if (!isEditEnabled) {
      setEditedVacancyText(vacancyTextData);
      setStoreEditedData(vacancyTextData); // Set the text only when enabling edit mode
    }
    setIsEditEnabled(!isEditEnabled); // Toggle the edit mode state
  };

  //handle Job description share functionality
  const handleShareByEmail = () => {
    handleShareEmail(vacancyId, vacancyTextData);
  };

  //This useEffect is used to set value of all the fields of this page based on change in vacancyTextData
  useEffect(() => {
    if (vacancyTextData) {
      setRoleOverView(vacancyTextData?.Role_Details);
      setCompanyOverView(vacancyTextData?.Company_Overview);
      setFittingTraits(vacancyTextData?.fitting_traits);
      setReasonToJoin(vacancyTextData?.reasons_to_join);
      setCriteria(vacancyTextData?.Criteria);
      setEmploymentConditions(vacancyTextData?.Employment_Condition);
      const Keys = Object.keys(vacancyTextData?.Trending_Titles);
      const trendingTitles = vacancyTextData?.Trending_Titles;
      const datas = trendingTitles.map((data) => Object.keys(data).join(","));

      setTrendingTitles(datas.join(", "));
      const trendingValues = trendingTitles
        ?.map((data) => Object.values(data))
        .flat()
        .join(" ");

      const sentencesArray = trendingValues.split(".");

      // Step 2: Remove empty entries and rejoin with <br /> for line breaks
      const formattedString = sentencesArray
        .filter((sentence) => sentence.trim() !== "") // Filter out any empty strings from the split
        .map((sentence) => sentence.trim()) // Trim each sentence
        .join(".<br />");
      setTrendingTitlesTooltipValue(formattedString);
      // Initializing an array to store the tooltip values
      const trendingTitlesTooltipValue = [];

      // Iterating over the keys to extract and append values
      Keys.forEach((ele) => {
        // Access the value associated with the current key and store it in an array
        trendingTitlesTooltipValue.push(vacancyTextData?.Trending_Titles[ele]);
      });

      const elements = vacancyTextData?.Elements_of_values || [];
      const keysArray = [];
      const valuesArray = [];

      elements.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          keysArray.push(key);
          valuesArray.push(value);
        });
      });


      setElementOfValues(keysArray.join(", "));
      setElementOfTooltipValues(valuesArray.join("<br />"));

      const neuromarketing = vacancyTextData?.neuromarketing_principles || [];
      const neuromarketingArray = [];
      const neuromarketingValuesArray = [];

      neuromarketing.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          neuromarketingArray.push(key);
          neuromarketingValuesArray.push(value);
        });
      });
      setNeuroMarketingValues(neuromarketingArray.join(", "));
      setNeuromarketingTooltipValues(neuromarketingValuesArray.join("<br />"));
    }
    setEditedVacancyText(vacancyTextData);
    setStoreEditedData(vacancyTextData);
  }, [vacancyTextData]);

  //handleBeautifyTemplate
  const handleBeautifyTemplate = () => {
    const payload = {
      structured_output: JSON.stringify(storeEditedData),
    };

    dispatch(getBeautifiedData(payload));
  };

  //Focus Job Title Field when edit is enabled
  useEffect(() => {
    if (isEditEnabled && isEditFocus?.current) {
      isEditFocus?.current?.focus();
    }
  }, [isEditEnabled]);

  //Copy Job Title Content
  const handleCopyJobTitleContents = () => {
    const jobTitle =
      editedVacancyText?.["Job_Title"] ?? vacancyTextData?.["Job_Title"];

    if (jobTitle) {
      navigator.clipboard
        .writeText(jobTitle)
        .then(() => {
          toast.success("Job Title copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy Job Title:");
        });
    } else {
      alert("No Job Title to copy!");
    }
  };

  //Copy StoryTelling Content

  const handleCopyStoryTellingContents = () => {
    const storyTelling =
      editedVacancyText?.["Storytelling"] ?? vacancyTextData?.["Storytelling"];

    if (storyTelling) {
      navigator.clipboard
        .writeText(storyTelling)
        .then(() => {
          toast.success("StoryTelling copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy storyTelling");
        });
    } else {
      alert("No storyTelling to copy!");
    }
  };

  //Copy Who we are
  const handleWhoWeAreContent = () => {
    const companyOverview =
      editedVacancyText?.Company_Overview["Who_Are_We"] ??
      vacancyTextData?.Company_Overview["Who_Are_We"];

    if (companyOverview) {
      navigator.clipboard
        .writeText(companyOverview)
        .then(() => {
          toast.success("Company Overview copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy Company Overview");
        });
    } else {
      alert("No Company Overview to copy!");
    }
  };

  //Copy RoleDetails
  const handleRoleDetails = (key) => {
    let companyOverview;
    switch (key) {
      case "Role_Overview":
        companyOverview =
          editedVacancyText?.Role_Details["Role_Overview"] ??
          vacancyTextData?.Role_Details["Role_Overview"];
        break;
      case "Motivation_for_Role":
        companyOverview =
          editedVacancyText?.Role_Details["Motivation_for_Role"] ??
          vacancyTextData?.Role_Details["Motivation_for_Role"];
        break;
      case "What_activities_can_you_expect_during_a_working_day?":
        companyOverview =
          editedVacancyText?.Role_Details[
          "What_activities_can_you_expect_during_a_working_day?"
          ] ??
          vacancyTextData?.Role_Details[
          "What_activities_can_you_expect_during_a_working_day?"
          ];
        break;

      default:
        break;
    }

    if (companyOverview) {
      navigator.clipboard
        .writeText(companyOverview)
        .then(() => {
          toast.success("Role Details copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy Role Details");
        });
    } else {
      alert("No Role Details to copy!");
    }
  };

  //Copy Job Requirements
  const handleJobRequirementsContents = () => {
    const resultantItem = criteria
      ?.map((item) => {
        const [key] = Object.entries(item)[0];
        const result =
          criteria?.find((item) => Object.keys(item)[0] === key)?.[key] || "";
        return `${key}: ${result}`;
      })
      .join("\n");

    if (resultantItem) {
      navigator.clipboard
        .writeText(resultantItem)
        .then(() => {
          toast.success("Job requirements copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy Job requirements");
        });
    } else {
      alert("No Job requirements to copy!");
    }
  };

  //Copy characteristics

  const handleCharacteristicsContent = () => {
    const Characteristics =
      (
        editedVacancyText?.["fitting_traits"] ||
        vacancyTextData?.["fitting_traits"]
      )?.join("\n") || "";

    if (Characteristics) {
      navigator.clipboard
        .writeText(Characteristics)
        .then(() => {
          toast.success("Characteristics copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy Characteristics");
        });
    } else {
      alert("No Characteristics to copy!");
    }
  };

  //Copy Employment Condition
  const handleEmploymentConditionsContent = () => {
    const resultantItem = employmentConditions
      ?.map((item) => {
        const [key] = Object.entries(item)[0];
        const result =
          employmentConditions?.find((item) => Object.keys(item)[0] === key)?.[
          key
          ] || "";
        return `${key}: ${result}`;
      })
      .join("\n");

    if (resultantItem) {
      navigator.clipboard
        .writeText(resultantItem)
        .then(() => {
          toast.success("Employment Condition copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy Employment Condition");
        });
    } else {
      alert("No Employment Condition to copy!");
    }
  };

  //Copy Few Reasons to work
  const handleReasonsToWorkContent = () => {
    const reasonToJoin =
      (
        editedVacancyText?.["reasons_to_join"] ||
        vacancyTextData?.["reasons_to_join"]
      )?.join("\n") || "";

    if (reasonToJoin) {
      navigator.clipboard
        .writeText(reasonToJoin)
        .then(() => {
          toast.success("Reasons to join copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy Reasons to join");
        });
    } else {
      alert("No Reasons to join content to copy!");
    }
  };

  //Copy application instructions
  const handleCopyApplicationInstructions = () => {
    const companyOverview =
      editedVacancyText?.["application_instructions"] ??
      vacancyTextData?.["application_instructions"];

    if (companyOverview) {
      navigator.clipboard
        .writeText(companyOverview)
        .then(() => {
          toast.success("Company_Overview copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy Company_Overview");
        });
    } else {
      alert("No Company_Overview to copy!");
    }
  };

  //Function to handle Pdf download
  const handleVacancyTextDownload = async () => {
    try {
      setPdfDownloadStatus("OnGoing");
      const result = await dispatch(
        getSocialMediaTemplate(vacancyId, setTemplateData)
      );

    } catch (error) {

      // Show an error message to the user
      toast.error(
        "Failed to fetch social media template. Please try again later."
      );
    }
  };

  useEffect(() => {
    if (pdfDownloadStatus == "OnGoing") {
      setTimeout(() => {
        generatePdf();
        setPdfDownloadStatus("finished");
      }, 1000);
    }
  }, [templateData]);

  //Function used while downloading Pdf
  function generatePdf() {
    const element = pdfRef.current;
    if (element) {
      element.style.display = "block";
      element.style.position = "static";

      const options = {
        margin: 10,
        image: { type: "png", quality: 1 },
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] }, // Avoid splitting elements
      };

      html2pdf()
        .from(element)
        .set(options)
        .toPdf()
        .get("pdf")
        .then((pdfDoc) => {
          // Restore styles
          element.style.display = "none";
          element.style.position = "absolute";
          element.style.left = "-9999px";

          pdfDoc.save("JobDescription.pdf");
          toast.success("PDF downloaded successfully");
        });
    }
  }
  //check if all keys have empty values
  const checkAllKeys = (key) => {
    const allEmpty = vacancyTextData?.[key]?.every((item) => item === "");
  };

  return (
    <Box>
      {chatbotDataLoading ? (
        <Loader />
      ) : (
        <>
          <Otherpopup
            open={openToneDialog}
            customTone={customTone}
            setCustomTone={setCustomTone}
            handleClose={handleClose}
          ></Otherpopup>
          <CancellationPopup
            open={isCancellationPopupOpen}
            handleClose={handleClosePopup}
            title={"Confirmation Popup"}
            question={"Are You Sure You Want To Discard?"}
            option1={"Yes"}
            option2={"No"}
            onOption1Click={handleOption1Click}
            onOption2Click={handleOption2Click}
          />
          <Header />
          <Box className="py-2 mt-10">
            <Grid container spacing={2} className="px-5">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className=" py-2">
                <Typography variant="h2" className="fs-32 fw-600 px-4">
                  Job Vacancy Text
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className=" py-2 flex-wrap justify-content-end"
              >
                <Box className="gap-10 flex-wrap">
                  <Box className="flex-wrap justify-content-end">
                    {isEditEnabled ? (
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={editSelected}
                        onClick={handleEditMode}
                        style={{cursor:"pointer"}}
                      // style={{
                      //   cursor: "pointer",
                      //   borderRadius: "5px",
                      //   opacity: isFunctionalityDisabled ? 0.5 : 1,
                      //   pointerEvents: isFunctionalityDisabled
                      //     ? "none"
                      //     : "auto",
                      // }}
                      />
                    ) : (
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={editsvg}
                        style={{cursor:"pointer"}}
                        onClick={handleEditMode}
                      // style={{
                      //   cursor: "pointer",
                      //   borderRadius: "5px",
                      //   opacity: isFunctionalityDisabled ? 0.5 : 1,
                      //   pointerEvents: isFunctionalityDisabled
                      //     ? "none"
                      //     : "auto",
                      // }}
                      />
                    )}
                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={copysvg}
                      style={{cursor:"pointer"}}
                      onClick={handleCopyToClipboard}
                    // style={{
                    //   cursor: "pointer",
                    //   opacity: isFunctionalityDisabled ? 0.5 : 1,
                    //   pointerEvents: isFunctionalityDisabled
                    //     ? "none"
                    //     : "auto",
                    // }}
                    />
                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />

                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={downloadsvg}
                      style={{cursor:"pointer"}}
                      // style={{
                      //   cursor: "pointer",
                      //   opacity: isFunctionalityDisabled ? 0.5 : 1,
                      //   pointerEvents: isFunctionalityDisabled
                      //     ? "none"
                      //     : "auto",
                      // }}
                      alt="Download PDF"
                      title="Download PDF"
                      onClick={handleVacancyTextDownload}
                    />

                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={sharesvg}
                      style={{cursor:"pointer"}}
                      // style={{
                      //   cursor: "pointer",
                      //   opacity: isFunctionalityDisabled ? 0.5 : 1,
                      //   pointerEvents: isFunctionalityDisabled
                      //     ? "none"
                      //     : "auto",
                      // }}
                      onClick={handleShareByEmail}
                    />
                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    <Box className="flex-wrap gap-10">
                      <div>
                        <Button
                          className="primary-btn-outline gap-10"
                          sx={{ minWidth: "fit-content" }}
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        // disabled={isFunctionalityDisabled}
                        >
                          <img src={sparksvg} />
                          Tone of Voice <KeyboardArrowDownIcon />
                        </Button>

                        <Menu
                          className="toneofvoice"
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("friendlyTone")
                            }
                          >
                            Friendly Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("innovativeTone")
                            }
                          >
                            Innovative Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("professionalTone")
                            }
                          >
                            Professional Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("inspirationalTone")
                            }
                          >
                            Inspirational Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("informativeTone")
                            }
                          >
                            Informative Tone
                          </MenuItem>
                          <MenuItem onClick={() => handleChange("others")}>
                            Others
                          </MenuItem>
                        </Menu>
                      </div>
                      {/* {isFunctionalityDisabled ? (
                        <Button
                          className="primary-btn-outline gap-10"
                          sx={{ minWidth: "fit-content" }}
                          disabled
                        >
                          <img src={addsvg} />
                          Generate the Social Media Template
                        </Button>
                      ) : (
                        <Link
                          to={`/job/upload/${vacancyId}`}
                          state={{ editedData: editedVacancyText }}
                        >
                          <Button
                            className="primary-btn-outline gap-10 socialmediapopup"
                            sx={{ minWidth: "fit-content" }}
                          >
                            <img src={addsvg} />
                            Generate the Social Media Template
                          </Button>
                        </Link>
                      )} */}

                      <Link
                        to={`/job/upload/${vacancyId}`}
                        state={{ editedData: editedVacancyText }}
                      >
                        <Button
                          className="primary-btn-outline gap-10 socialmediapopup"
                          sx={{ minWidth: "fit-content" }}
                        >
                          <img src={addsvg} />
                          Generate the Social Media Template
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={9} className="">
                <Box className="vacancy-content" ref={contentRef1}>
                  {(editedVacancyText?.["Job_Title"] ||
                    vacancyTextData?.["Job_Title"]) && (
                      <>
                        <Typography
                          className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                          variant="h3"
                        >
                          {selectedLanguage === "en"
                            ? "Job Title"
                            : selectedLanguage === "nl"
                              ? UpdatedVacancyHeading?.Job_Title?.Dutch
                              : selectedLanguage === "fr" ? UpdatedVacancyHeading?.Job_Title?.French
                                : selectedLanguage === "de" ? UpdatedVacancyHeading?.Job_Title?.German
                                  : UpdatedVacancyHeading?.Job_Title?.Spanish}
                          <span>
                            <img
                              className="my-auto"
                              width={27}
                              height={27}
                              src={copysvg}
                              onClick={handleCopyJobTitleContents}
                            // style={{
                            //   cursor: "pointer",
                            //   opacity: isFunctionalityDisabled ? 0.5 : 1,
                            //   pointerEvents: isFunctionalityDisabled
                            //     ? "none"
                            //     : "auto",
                            // }}
                            />
                          </span>
                        </Typography>
                        <TextareaAutosize
                          ref={isEditFocus}
                          className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                          aria-label="minimum height"
                          minRows={1}
                          placeholder="Enter Job Title"
                          style={{ width: "100% " }}
                          value={
                            editedVacancyText?.["Job_Title"] !== undefined
                              ? editedVacancyText?.["Job_Title"]
                              : vacancyTextData?.["Job_Title"]
                          }
                          disabled={!isEditEnabled}
                          onChange={(e) =>
                            handleEditData("Job_Title", e.target.value)
                          }
                        />
                      </>
                    )}

                  {(editedVacancyText?.["Storytelling"] ||
                    vacancyTextData?.["Storytelling"]) && (
                      <>
                        <Typography
                          className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                          variant="h3"
                        >
                          {selectedLanguage === "en"
                            ? "Storytelling"
                            : selectedLanguage === "nl"
                              ? UpdatedVacancyHeading?.Storytelling?.Dutch
                              : selectedLanguage === "fr" ? UpdatedVacancyHeading?.Storytelling?.French : selectedLanguage === "de" ? UpdatedVacancyHeading?.Storytelling?.German : UpdatedVacancyHeading?.Storytelling?.Spanish}
                          <span>
                            <img
                              className="my-auto"
                              width={27}
                              height={27}
                              src={copysvg}
                              onClick={handleCopyStoryTellingContents}
                              style={{
                                cursor: "pointer",
                                opacity: isFunctionalityDisabled ? 0.5 : 1,
                                pointerEvents: isFunctionalityDisabled
                                  ? "none"
                                  : "auto",
                              }}
                            />
                          </span>
                        </Typography>
                        <TextareaAutosize
                          ref={copyStoryTellingRef}
                          className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                          aria-label="minimum height"
                          minRows={1}
                          placeholder="Enter Story Telling"
                          style={{ width: "100% " }}
                          disabled={!isEditEnabled}
                          value={
                            editedVacancyText?.["Storytelling"] !== undefined
                              ? editedVacancyText?.["Storytelling"]
                              : vacancyTextData?.["Storytelling"]
                          }
                          onChange={(e) =>
                            handleEditData("Storytelling", e.target.value)
                          }
                        />
                      </>
                    )}

                  {Object.entries(companyOverView).map(([key, value]) => {
                    return (
                      value && (
                        <div key={key}>
                          <Typography
                            className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                            variant="h3"
                          >
                            {key === "Who_Are_We" && selectedLanguage === "en"
                              ? "Who are we?"
                              : selectedLanguage === "nl"
                                ? UpdatedVacancyHeading?.Who_Are_We?.Dutch
                                : selectedLanguage === "fr" ? UpdatedVacancyHeading?.Who_Are_We?.French : selectedLanguage === "de" ? UpdatedVacancyHeading?.Who_Are_We?.French : UpdatedVacancyHeading?.Who_Are_We?.Spanish}
                            <span>
                              <img
                                className="my-auto"
                                width={27}
                                height={27}
                                src={copysvg}
                                onClick={handleWhoWeAreContent}
                                style={{
                                  cursor: "pointer",
                                  opacity: isFunctionalityDisabled ? 0.5 : 1,
                                  pointerEvents: isFunctionalityDisabled
                                    ? "none"
                                    : "auto",
                                }}
                              />
                            </span>
                          </Typography>
                          <TextareaAutosize
                            className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                            aria-label="minimum height"
                            minRows={1}
                            value={
                              editedVacancyText?.Company_Overview?.[key] !==
                                undefined
                                ? editedVacancyText?.Company_Overview?.[key]
                                : vacancyTextData?.Company_Overview?.[key]
                            }
                            onChange={(e) =>
                              handleEditCompanyOverviewData(key, e.target.value)
                            }
                            style={{ width: "100%", marginBottom: "1rem" }}
                            disabled={!isEditEnabled}
                          />
                        </div>
                      )
                    );
                  })}

                  {Object.entries(roleOverView).map(([key, value]) => {
                    return (
                      value && (
                        <div key={key}>
                          <Typography
                            className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                            variant="h3"
                          >
                            {key === "Role_Overview"
                              ? selectedLanguage === "en"
                                ? UpdatedVacancyHeading?.Role_Details
                                  ?.Role_Overview?.English
                                : selectedLanguage === "nl"
                                  ? UpdatedVacancyHeading?.Role_Details
                                    ?.Role_Overview?.Dutch
                                  : selectedLanguage === "fr" ? UpdatedVacancyHeading?.Role_Details
                                    ?.Role_Overview?.French : selectedLanguage === "de" ? UpdatedVacancyHeading?.Role_Details
                                      ?.Role_Overview?.German : UpdatedVacancyHeading?.Role_Details
                                        ?.Role_Overview?.Spanish
                              : key === "Motivation_for_Role"
                                ? selectedLanguage === "en"
                                  ? UpdatedVacancyHeading?.Role_Details
                                    ?.Motivation_for_Role?.English
                                  : selectedLanguage === "nl"
                                    ? UpdatedVacancyHeading?.Role_Details
                                      ?.Motivation_for_Role?.Dutch
                                    : selectedLanguage === "fr" ? UpdatedVacancyHeading?.Role_Details
                                      ?.Motivation_for_Role?.French : selectedLanguage === "de" ? UpdatedVacancyHeading?.Role_Details
                                        ?.Motivation_for_Role?.German : UpdatedVacancyHeading?.Role_Details
                                          ?.Motivation_for_Role?.Spanish
                                : key ===
                                  "What_activities_can_you_expect_during_a_working_day?"
                                  ? selectedLanguage === "en"
                                    ? UpdatedVacancyHeading?.Role_Details
                                      ?.What_activities_can_you_expect_during_a_working_day
                                      ?.English
                                    : selectedLanguage === "nl"
                                      ? UpdatedVacancyHeading?.Role_Details
                                        ?.What_activities_can_you_expect_during_a_working_day
                                        ?.Dutch
                                      : selectedLanguage === "fr" ? UpdatedVacancyHeading?.Role_Details
                                        ?.What_activities_can_you_expect_during_a_working_day
                                        ?.French : selectedLanguage === "de" ? UpdatedVacancyHeading?.Role_Details
                                          ?.What_activities_can_you_expect_during_a_working_day
                                          ?.German : UpdatedVacancyHeading?.Role_Details
                                            ?.What_activities_can_you_expect_during_a_working_day
                                        ?.Spanish
                                  : key}

                            <span>
                              <img
                                className="my-auto"
                                width={27}
                                height={27}
                                src={copysvg}
                                onClick={() => handleRoleDetails(key)}
                                style={{
                                  cursor: "pointer",
                                  opacity: isFunctionalityDisabled ? 0.5 : 1,
                                  pointerEvents: isFunctionalityDisabled
                                    ? "none"
                                    : "auto",
                                }}
                              />
                            </span>
                          </Typography>
                          <TextareaAutosize
                            className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                            aria-label="minimum height"
                            minRows={1}
                            value={
                              editedVacancyText?.Role_Details?.[key] !==
                                undefined
                                ? editedVacancyText?.Role_Details?.[key]
                                : vacancyTextData?.Role_Details?.[key]
                            }
                            onChange={(e) =>
                              handleEditRoleDetailsData(key, e.target.value)
                            }
                            style={{ width: "100%", marginBottom: "1rem" }}
                            disabled={!isEditEnabled}
                          />
                        </div>
                      )
                    );
                  })}

                  {!isCriteriaEmpty() && (
                    <Typography
                      className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                      variant="h3"
                    >
                      {selectedLanguage === "en"
                        ? "The job requirements:"
                        : selectedLanguage === "nl"
                          ? UpdatedVacancyHeading?.job_requirement?.Dutch
                          : selectedLanguage === "fr" ? UpdatedVacancyHeading?.job_requirement?.French
                            : selectedLanguage === "de" ? UpdatedVacancyHeading?.job_requirement?.German
                              : UpdatedVacancyHeading?.job_requirement?.Spanish}
                      <span>
                        <img
                          className="my-auto"
                          width={27}
                          height={27}
                          src={copysvg}
                          onClick={handleJobRequirementsContents}
                          style={{
                            cursor: "pointer",
                            opacity: isFunctionalityDisabled ? 0.5 : 1,
                            pointerEvents: isFunctionalityDisabled
                              ? "none"
                              : "auto",
                          }}
                        />
                      </span>
                    </Typography>
                  )}

                  {!isCriteriaEmpty() &&
                    (criteria || []).map((criteriaItem) => {
                      const [key, value] = Object.entries(criteriaItem)[0];
                      return (
                        <div key={key}>
                          {isEditEnabled ? (
                            <div className="fs-16 text-gray-c1 fw-500 mt-10 no-border job">
                              <strong>{key}:</strong>
                              <TextareaAutosize
                                className="ml-10"
                                aria-label="minimum height"
                                minRows={1}
                                placeholder="Enter Job Criteria"
                                style={{ width: "calc(100% - 50px)" }}
                                //   value={value || ""}
                                value={
                                  editedVacancyText?.Criteria?.find(
                                    (item) => Object.keys(item)[0] === key
                                  )?.[key] || ""
                                }
                                onChange={(e) =>
                                  handleCriteriaEditData(key, e.target.value)
                                }
                              />
                            </div>
                          ) : (
                            value !== "" && (
                              <List
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "20px",
                                }}
                              >
                                <ListItem
                                  key={key}
                                  className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                                  style={{
                                    display: "list-item",
                                    listStylePosition: "inside",
                                    color: "black",
                                  }}
                                >
                                  <>
                                    <span style={{ fontWeight: "bold" }}>
                                      {key}
                                    </span>
                                    <span>: {value}</span>
                                  </>
                                </ListItem>
                              </List>
                            )
                          )}
                        </div>
                      );
                    })}

                  <div>
                    <Typography
                      className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                      variant="h3"
                    >
                      {selectedLanguage === "en"
                        ? "These characteristics suit you:"
                        : selectedLanguage === "nl"
                          ? UpdatedVacancyHeading?.characteristics?.Dutch
                          : selectedLanguage === "fr" ? UpdatedVacancyHeading?.characteristics?.French
                            : selectedLanguage === "de" ? UpdatedVacancyHeading?.characteristics?.German
                              : UpdatedVacancyHeading?.characteristics?.Spanish}
                      <span>
                        <img
                          className="my-auto"
                          width={27}
                          height={27}
                          src={copysvg}
                          onClick={handleCharacteristicsContent}
                          style={{
                            cursor: "pointer",
                            opacity: isFunctionalityDisabled ? 0.5 : 1,
                            pointerEvents: isFunctionalityDisabled
                              ? "none"
                              : "auto",
                          }}
                        />
                      </span>
                    </Typography>

                    {isEditEnabled ? (
                      <TextareaAutosize
                        className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                        aria-label="minimum height"
                        minRows={1}
                        placeholder="Enter About The Function"
                        style={{ width: "100%" }}
                        value={
                          (
                            editedVacancyText?.["fitting_traits"] ||
                            vacancyTextData?.["fitting_traits"]
                          )?.join("\n") || ""
                        }
                        onChange={(e) =>
                          handleFunctionEditData(
                            "fitting_traits",
                            e.target.value.split("\n")
                          )
                        }
                      />
                    ) : (
                      <List
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "20px",
                          color: "black !important",
                        }}
                      >
                        {fittingTraits?.map(
                          (item, index) =>
                            item !== "" && (
                              <ListItem
                                key={index}
                                className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                                style={{
                                  display: "list-item",
                                  listStylePosition: "inside",
                                  color: "black",
                                }}
                              >
                                {item}
                              </ListItem>
                            )
                        )}
                      </List>
                    )}
                  </div>
                  {/* ))} */}

                  {checkAllKeys("reasons_to_join") && <h1>Yes</h1>}

                  {!isEmploymentConditionsEmpty() && (
                    <Typography
                      className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                      variant="h3"
                    >
                      {selectedLanguage === "en"
                        ? "These are your employment conditions:"
                        : selectedLanguage === "nl"
                          ? UpdatedVacancyHeading?.employment_conditions?.Dutch
                          : selectedLanguage === "fr" ? UpdatedVacancyHeading?.employment_conditions?.French
                            : selectedLanguage === "de" ? UpdatedVacancyHeading?.employment_conditions?.Dutch
                              : UpdatedVacancyHeading?.employment_conditions?.Spanish}
                      <span>
                        <img
                          className="my-auto"
                          width={27}
                          height={27}
                          src={copysvg}
                          onClick={handleEmploymentConditionsContent}
                          style={{
                            cursor: "pointer",
                            opacity: isFunctionalityDisabled ? 0.5 : 1,
                            pointerEvents: isFunctionalityDisabled
                              ? "none"
                              : "auto",
                          }}
                        />
                      </span>
                    </Typography>
                  )}


                  {!isEmploymentConditionsEmpty() &&
                    (employmentConditions || [])?.map((employmentCriteria) => {
                      const [key, value] =
                        Object.entries(employmentCriteria)[0];
                      // Destructure here properly
                      return (
                        <div key={key}>
                          {isEditEnabled ? (
                            <div className="fs-16 text-gray-c1 fw-500 mt-10 no-border job">
                              <strong>{key}:</strong>
                              <TextareaAutosize
                                className="ml-10"
                                aria-label="minimum height"
                                minRows={1}
                                placeholder="Enter Employment Condition"
                                style={{ width: "100%" }}
                                value={
                                  editedVacancyText?.Employment_Condition?.find(
                                    (item) => Object.keys(item)[0] === key
                                  )?.[key] || ""
                                }
                                onChange={(e) =>
                                  handleEmploymentEditData(key, e.target.value)
                                } // Ensure proper handler
                              />
                            </div>
                          ) : (
                            value !== "" && (
                              <List
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "20px",
                                }}
                              >
                                <ListItem
                                  key={key}
                                  className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                                  style={{
                                    display: "list-item",
                                    listStylePosition: "inside",
                                    color: "black",
                                  }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    {key}
                                  </span>
                                  <span>: {value}</span>
                                </ListItem>
                              </List>
                            )
                          )}
                        </div>
                      );
                    })}
                  {checkAllKeys("reasons_to_join") && <h1>Yes</h1>}

                  {!isReasonsToJoinEmpty() && (
                    <>
                      <Typography
                        className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                        variant="h3"
                      >
                        {selectedLanguage === "en"
                          ? UpdatedVacancyHeading?.reasons_to_join?.English
                          : selectedLanguage === "nl"
                            ? UpdatedVacancyHeading?.reasons_to_join?.Dutch
                            : selectedLanguage === "fr" ? UpdatedVacancyHeading?.reasons_to_join?.French
                              : selectedLanguage === "de" ? UpdatedVacancyHeading?.reasons_to_join?.German
                                : UpdatedVacancyHeading?.reasons_to_join?.Spanish}
                        <span>
                          <img
                            className="my-auto"
                            width={27}
                            height={27}
                            src={copysvg}
                            onClick={handleReasonsToWorkContent}
                            style={{
                              cursor: "pointer",
                              opacity: isFunctionalityDisabled ? 0.5 : 1,
                              pointerEvents: isFunctionalityDisabled
                                ? "none"
                                : "auto",
                            }}
                          />
                        </span>
                      </Typography>
                      <div>
                        {isEditEnabled ? (
                          <TextareaAutosize
                            className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                            aria-label="minimum height"
                            minRows={1}
                            placeholder="Enter About The Function"
                            style={{ width: "100%" }}
                            value={
                              (
                                editedVacancyText?.["reasons_to_join"] ||
                                vacancyTextData?.["reasons_to_join"]
                              )?.join("\n") || ""
                            }
                            onChange={(e) =>
                              handleFunctionEditData(
                                "reasons_to_join",
                                e.target.value.split("\n")
                              )
                            }
                          />
                        ) : (
                          <List
                            style={{
                              listStyleType: "disc",
                              paddingLeft: "20px",
                            }}
                          >
                            {reasonToJoin?.map(
                              (item, index) =>
                                item !== "" && (
                                  <ListItem
                                    key={index}
                                    className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                                    style={{
                                      display: "list-item",
                                      listStylePosition: "inside",
                                      color: "black",
                                    }}
                                  >
                                    {item}
                                  </ListItem>
                                )
                            )}
                          </List>
                        )}
                      </div>
                    </>
                  )}
                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    {selectedLanguage === "en"
                      ? UpdatedVacancyHeading?.application_instructions?.English
                      : selectedLanguage === "nl"
                        ? UpdatedVacancyHeading?.application_instructions?.Dutch
                        : selectedLanguage === "fr" ? UpdatedVacancyHeading?.application_instructions?.French
                          : selectedLanguage === "de" ? UpdatedVacancyHeading?.application_instructions?.German
                            : UpdatedVacancyHeading?.application_instructions?.Spanish}

                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={handleCopyApplicationInstructions}
                        style={{
                          cursor: "pointer",
                          opacity: isFunctionalityDisabled ? 0.5 : 1,
                          pointerEvents: isFunctionalityDisabled
                            ? "none"
                            : "auto",
                        }}
                      />
                    </span>
                  </Typography>
                  <TextareaAutosize
                    ref={copyStoryTellingRef}
                    className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                    aria-label="minimum height"
                    minRows={1}
                    placeholder="Enter Story Telling"
                    style={{ width: "100% " }}
                    disabled={!isEditEnabled}
                    value={
                      editedVacancyText?.["application_instructions"] !==
                        undefined
                        ? editedVacancyText?.["application_instructions"]
                        : vacancyTextData?.["application_instructions"]
                    }
                    onChange={(e) =>
                      handleEditData("application_instructions", e.target.value)
                    }
                  />
                </Box>

                <TestPdfTemplate ref={pdfRef} templateData={templateData} />
                <Box className="flex-wrap justify-content-between my-30">
                  <Button
                    className="primary-btn "
                    onClick={handleBeautifyTemplate}
                  // disabled={isFunctionalityDisabled}
                  >
                    Beautify Template{" "}
                  </Button>
                  <Box>
                    <Button
                      onClick={() => {
                        handleOpenPopup();
                      }}
                      className="primary-btn-outline mr-10"
                      sx={{ minWidth: "144px" }}
                    // disabled={isFunctionalityDisabled}
                    >
                      Discard
                    </Button>
                    <Button
                      disabled={!isEditEnabled}
                      onClick={handleSubmit}
                      className="primary-btn"
                      sx={{ minWidth: "144px" }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                <Box
                  sx={{
                    background: "#FFF3E9",
                    height: "100%",
                    padding: "28px 24px ",
                  }}
                  ref={contentRef2}
                >
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={JobTitle} />
                        &nbsp;{" "}
                        {selectedLanguage === "en"
                          ? "Job Title"
                          : selectedLanguage === "nl"
                            ? UpdatedVacancyHeading?.Job_Title?.Dutch
                            : selectedLanguage === "fr" ? UpdatedVacancyHeading?.Job_Title?.French
                              : selectedLanguage === "de" ? UpdatedVacancyHeading?.Job_Title?.German
                                : UpdatedVacancyHeading?.Job_Title?.Spanish}
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: trendingTitlesTooltipValue,
                            }}
                          />
                        }
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {trendingTitles}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={ElementsofValue} />
                        &nbsp;{" "}
                        {selectedLanguage === "en"
                          ? "Elements of Value"
                          : selectedLanguage === "nl"
                            ? UpdatedVacancyHeading?.elements_of_value?.Dutch
                            : selectedLanguage === "fr" ? UpdatedVacancyHeading?.elements_of_value?.French
                              : selectedLanguage === "de" ? UpdatedVacancyHeading?.elements_of_value?.German
                                : UpdatedVacancyHeading?.elements_of_value?.Spanish}
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: elementOfTooltipValues,
                            }}
                          />
                        }
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {elementOfValues}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={Neuromarketing} />
                        &nbsp;{" "}
                        {selectedLanguage === "en"
                          ? "Neuromarketing"
                          : selectedLanguage === "nl"
                            ? UpdatedVacancyHeading?.neuromarketing?.Dutch
                            : selectedLanguage === "fr" ? UpdatedVacancyHeading?.neuromarketing?.French
                              : selectedLanguage === "de" ? UpdatedVacancyHeading?.neuromarketing?.German
                                : UpdatedVacancyHeading?.neuromarketing?.Spanish}
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: neuromarketingTooltipValues,
                            }}
                          />
                        }
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {neuromarketingValues}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={ToneofVoice} />
                        &nbsp;{" "}
                        {selectedLanguage === "en"
                          ? "Tone of Voice"
                          : selectedLanguage === "nl"
                            ? UpdatedVacancyHeading?.tone_of_voice?.Dutch
                            : selectedLanguage === "fr" ? UpdatedVacancyHeading?.tone_of_voice?.French
                              : selectedLanguage === "de" ? UpdatedVacancyHeading?.tone_of_voice?.German
                                : UpdatedVacancyHeading?.tone_of_voice?.Spanish}
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={vacancyTextData?.Tone_of_voice}
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {vacancyTextData?.Tone_of_voice}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Footer />
        </>
      )}
    </Box>
  );
};

export default UpdatedVacancy;
