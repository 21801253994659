import { toast } from "react-toastify";
import Utils from "../../utils/reduxUtils";


export const chatbotData = (value,navigate) => {
  return async (dispatch) => {
    dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: true });
    try {
      const successCallback = (response) => {
        if (response?.status === 200) {
          
          toast.success(response?.data?.message, {
            autoClose: 2000,
          });
          dispatch({ type: Utils.ActionName.CHATBOTDATA, payload: response?.data });
          dispatch({ type: Utils.ActionName.VACANCYID, payload: response?.data });
        } else {
          toast.error(response?.data?.message, {
            autoClose: 2000,
          });
        }
        dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
      };

      const errorCallback = (error) => {
        toast.error(`${error?.data?.message?.message}.`, {
          autoClose: 2000,
        });
          navigate('/pricing')
        dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
       
      };

      await Utils.api.postApiCall(
        Utils.EndPoint.vacancyTextInfo,
        value,
        successCallback,
        errorCallback,
        ""
      );
    } catch (error) {
      toast.error(error?.data?.message, {
        autoClose: 2000,
      });
      dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
    }
  };
};

// export const editedVacancyTextData = (value,setEditedVacancyText,setIsEditEnabled) => {

//   return async (dispatch) => {

//     try {
//       const successCallback = (response) => {
//         if (response?.status === 200) {
//           toast.success("Text Edited Successfully");
//           dispatch({ type: Utils.ActionName.EDITED_VACANCY_TEXT, payload: response?.data });
//           setEditedVacancyText(null);
//           setIsEditEnabled(false);
//         } else {
//           toast.error("Unable to edit text");
//         }
       
//       };

//       const errorCallback = (error) => {
//         toast.error(error?.data?.message, {
//           autoClose: 2000,
//         });
//         dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
//       };

//       await Utils.api.putApiCall(
//         Utils.EndPoint.editedVacancyText,
//         value,
//         successCallback,
//         errorCallback,
//         ""
//       );
//     } catch (error) {
//       toast.error(error?.data?.message, {
//         autoClose: 2000,
//       });
//       dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
//     }
//   };
// };



export const editedVacancyTextData = (value, setEditedVacancyText, setIsEditEnabled) => {
  return async (dispatch) => {
    try {
      const response = await Utils.api.putApiCall(Utils.EndPoint.editedVacancyText, value);

      if (response?.status === 200) {
        toast.success("Text Edited Successfully");
        // dispatch({ type: Utils.ActionName.EDITED_VACANCY_TEXT, payload: response?.data });
        // setEditedVacancyText(null);
        // setIsEditEnabled(false);
      } else {
        toast.error("Unable to edit text");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred", {
        autoClose: 2000,
      });
      dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
    }
  };
};



export const toneOfVoice = (value) => {
 return async (dispatch) => {
    dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: true });
    try {
      const successCallback = (response) => {
        if (response?.status === 200) {
          toast.success(response?.data?.message, {
            autoClose: 2000,
          });
          dispatch({ type: Utils.ActionName.TONE_OF_VOICE, payload: response?.data });
        } else {
          toast.error(response?.data?.message, {
            autoClose: 2000,
          });
        }
        dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
      };
       const errorCallback = (error) => {
        toast.error(error?.data?.message, {
          autoClose: 2000,
        });
        dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
      };

      await Utils.api.postApiCall(
        Utils.EndPoint.toneOfVoiceInfo,
        value,
        successCallback,
        errorCallback,
        ""
      );
    } catch (error) {
      toast.error(error?.data?.message, {
        autoClose: 2000,
      });
      dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
    }
  };
};

  export const jobDescriptionFileUpload = (value) => {
    return async () => {
      try {
        const successCallback = (response) => {
          if (response?.status === 201) {
            toast.success(response?.data?.message, {
              autoClose: 2000,
            });
          } else {
            toast.error(response?.data?.message, {
              autoClose: 2000,
            });
          }
        };
  
        const errorCallback = (error) => {
          toast.error(error?.data?.message, {
            autoClose: 2000,
          });
        };
  
        await Utils.api.postApiCall(
          Utils.EndPoint.chatbotFileUpload,
          value,
          successCallback,
          errorCallback,
          "multi"
        );
      } catch (error) {
        toast.error(error?.data?.message, {
          autoClose: 2000,
        });
      }
    };
  };

  export const socialMediaTemplateData = (value, setLoading, handleOpenPopup, setTemplate) => {
   return async (dispatch) => {
      dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: true });
      setLoading(true);
      try {
        const successCallback = (response) => {
          if (response?.status === 200) {
            setTemplate(response?.data);
            setLoading(false);
            handleOpenPopup();
            toast.success(response?.data?.message, {
              autoClose: 2000,
            });
          } else {
            setLoading(false);
            toast.error(response?.data?.message, {
              autoClose: 2000,
            });
          }
          dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
          
        };
  
        const errorCallback = (error) => {
          toast.error(error?.data?.message, {
            autoClose: 2000,
          });
          dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
        };
  
        await Utils.api.postApiCall(
          Utils.EndPoint.socialMediaTemplate,
          value,
          successCallback,
          errorCallback,
          ""
        );
      } catch (error) {
        toast.error(error?.data?.message, {
          autoClose: 2000,
        });
        dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
      }
    };
  };

  export const getVacancyTextDataForShare = (id) => {
    return (dispatch) => {
            Utils.api.getApiCall(
                Utils.EndPoint.vacancyTextInfoForShare(id),
                (resData) => { // Success callback
                    if (resData?.status === 200) {
                        toast.success(resData?.data?.message, {
                            autoClose: 2000,
                        });
                        dispatch({ type: Utils.ActionName.SHARE_VACANCY_TEXT, payload: resData.data });
                        // resolve(resData); 
                    } else {
                        toast.error(resData?.data?.message, {
                            autoClose: 2000,
                        });
                    
                    }
                },
                (error) => { 
                    toast.error(error?.data?.message, {
                        autoClose: 2000,
                    });
                },
                false, 
                "" 
            );
        // });
    };
};

export const getActiveVacancyTextData = (id) => {
  return (dispatch) => {
    dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: true });
      // return new Promise((resolve, reject) => {
          Utils.api.getApiCall(
              Utils.EndPoint.vacancyTextInfoForShare(id),
              (resData) => { // Success callback
                  if (resData?.status === 200) {
                      toast.success(resData?.data?.message, {
                          autoClose: 2000,
                      });
                      dispatch({ type: Utils.ActionName.GOTO_VACANCYTEXT_FROM_DASHBOARD, payload: resData.data });
                      dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
                  } else {
                      toast.error(resData?.data?.message, {
                          autoClose: 2000,
                      });
                  }
              },
              (error) => { 
                  toast.error(error?.data?.message, {
                      autoClose: 2000,
                  });
              },
              false, 
              "" 
          );
      // });
  };
};

export const getBeautifiedData = (value) => {
return async (dispatch) => {
    dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: true });
    try {
      const successCallback = (response) => {
        if (response?.status === 200) {
          
          toast.success(response?.data?.message, {
            autoClose: 2000,
          });
          dispatch({ type: Utils.ActionName.BEAUTIFY_TEMPLATE_DATA, payload: response?.data });
        } else {
          toast.error(response?.data?.message, {
            autoClose: 2000,
          });
        }
        dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
        
      };

      const errorCallback = (error) => {
        toast.error(error?.data?.message, {
          autoClose: 2000,
        });
        dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
      };

      await Utils.api.postApiCall(
        Utils.EndPoint.beautifyTemplate,
        value,
        successCallback,
        errorCallback,
        ""
      );
    } catch (error) {
      toast.error(error?.data?.message, {
        autoClose: 2000,
      });
      dispatch({ type: Utils.ActionName.CHATBOTDATA_LOADING, payload: false });
      // setLoading(false);
    }
  };
};

export const getSocialMediaTemplate  = (value, setTemplates) => {
  return (dispatch) => {
    // return new Promise((resolve, reject) => {
        Utils.api.getApiCall(
            Utils.EndPoint.getSocialMedia(value),
            (resData) => { // Success callback
                if (resData?.status === 200) {
                    toast.success(resData?.data?.message, {
                        autoClose: 2000,
                    });
                   setTemplates(resData.data);
                    // resolve(resData); 
                } else {
                    toast.error(resData?.data?.message, {
                        autoClose: 2000,
                    });
                    // reject(resData);
                }
            },
            (error) => { 
              setTemplates([])
            
            },
            false, 
            "" 
        );
    // });
};

}

export const saveAndShareAPI = (formData) => {
  return async (dispatch) => {
   try {
     await Utils.api.putApiCall(
        Utils.EndPoint.savePostImages,
        formData,
        "multi"
      );
      toast.success("Images saved and shared successfully!", {
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error?.data?.message, {
        autoClose: 2000,
      });
    }
  };
}

