const EndPoint = {
   login: "auth/recruiter-login",
   signup: "auth/recruiter-registeration",
   forgot: "/v1/auth/forgot-password",
   verifyOTP: "/v1/auth/verify-otp",
   resendOTP: "/v1/auth/resend-otp",
   resetPassword: "/v1/auth/reset-password",
   contactUs: "feedback",
   personalInfo:(id) => `/recruiter/personal-info/${id}`,
   companyInfo:(id) => `/recruiter/company-info/${id}`,
   profileInfo:(id) => `/recruiter/profile/${id}`,
   clientInfo:(id) => `/recruiter/client-info/${id}`,
   clientInfo:(id) => `/recruiter/client-info/${id}`,
   updateClientInfo:(id) => `/recruiter/client-info/update/${id}`,
   updateProfileImage:(id) => `/recruiter/profile-image/${id}`,
   deleteClientInfo:(id) => `/recruiter/client-info/${id}`,
   vacancyTextInfo:"/ai/getVacancyText",
   chatbotFileUpload:"/ai/getAnswersFromDocument",
   toneOfVoiceInfo:"/ai/toneOfVoice",
   editedVacancyText:"/vacancy-text/update",
   socialMediaTemplate:"/ai/generateSocialMediaPost",
   beautifyTemplate:"/ai/beautifyTemplate",
   vacancyTextInfoForShare:(id) => `/vacancy-text/get/${id}`,
   getAllSubscriptions:"/subscription",
   getSubscriptions:"/subscription",
   getSubscriptionById:(id) => `/subscription/${id}`,
   generatePaymentIntent:`/subscription/create-payment-intent`,
   paymentSucceded:"/subscription/payment-succeded",
   getSocialMedia:(id) => `/vacancy-text/get-social-media/${id}`,
   savePostImages:"/vacancy-text/update-vacancy-images",
   getChatbotSession:(id)=>`/chatbot/chatbot-session-get/${id}`,
   // getDashboardData:"vacancy-text/dashboard",
   getDashboardData:(params)=>{
    const queryParams=new URLSearchParams(params).toString();
    return `/vacancy-text/dashboard?${queryParams}`;
   },

   createChatbotSession:"/chatbot/session",
   getNextQuestion:(id) => `/chatbot/next-question/${id}`,
   getPreviousQuestion:(id) => `/chatbot/previous-question/${id}`,
};

export default EndPoint;