import Utils from "../../utils/reduxUtils";
import { toast } from "react-toastify";

export const profile = (id, value, navigate) => {
    return async (dispatch) => {
      try {
        const resData = await Utils.api.putApiCall(
          Utils.EndPoint.personalInfo(id),
          value,
          "application/json"
        );
         
        if (resData?.status === 201) {
          toast.success(resData?.data?.message, {
            autoClose: 2000,
          });
          return resData; // Return the response data when successful
        } else {
          toast.error(resData?.data?.message, {
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.error(
          error.response?.data?.message || "Network error or no response from server",
          {
            autoClose: 2000,
          }
        );
      }
    };
  };
  
  
export const companyInfo = (id, value) => {
  console.log("va55lue",value);
    return async (dispatch) => {
      try {
        const resData = await Utils.api.putApiCall(
          Utils.EndPoint.companyInfo(id),
          value,
          "multi"
        );
  
        if (resData?.status === 201) {
          toast.success(resData?.data?.message, {
            autoClose: 2000,
          });
          dispatch(getProfileInfo(id));
          return resData;
        } else {
          toast.error(resData?.data?.message, {
            autoClose: 2000,
          });
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.setItem("accessToken", "");
            window.location.reload();
          }
          toast.error(error.response.data?.message || "Please try again later", {
            autoClose: 2000,
          });
        } else {
          toast.error("Network error or no response from server", {
            autoClose: 2000,
          });
        }
      }
    };
  };
  


export const clientInfo = (id,value) => {
   return async (dispatch) => {
        try {
          const resData = await Utils.api.putApiCall(
            Utils.EndPoint.clientInfo(id),
            value,
            "application/json"
          );
    
          if (resData?.status === 201) {
            toast.success(resData?.data?.message, {
              autoClose: 2000,
            });
            dispatch(getProfileInfo(id));
            return resData;
          } else {
            toast.error(resData?.data?.message, {
              autoClose: 2000,
            });
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.setItem("accessToken", "");
              window.location.reload();
            }
            toast.error(error.response.data?.message || "Please try again later", {
              autoClose: 2000,
            });
          } else {
            toast.error("Network error or no response from server", {
              autoClose: 2000,
            });
          }
        }
      };

};

export const getProfileInfo = (id) => {
    return (dispatch) => {
        // return new Promise((resolve, reject) => {
            Utils.api.getApiCall(
                Utils.EndPoint.profileInfo(id),
                (resData) => { // Success callback
                    if (resData?.status === 200) {
                        toast.success(resData?.data?.message, {
                            autoClose: 2000,
                        });
                        dispatch({ type: Utils.ActionName.PROFILEINFO, payload: resData.data?.data });
                        // resolve(resData); 
                    } else {
                        toast.error(resData?.data?.message, {
                            autoClose: 2000,
                        });
                        // reject(resData); 
                    }
                },
                (error) => { 
                    toast.error(error?.data?.message, {
                        autoClose: 2000,
                    });
                    // reject(error); 
                },
                false, 
                "" 
            );
        // });
    };
};

//delete client information

export const deleteClientInfo = (id) => {
  return async (dispatch) => {
    try {
      const resData = await Utils.api.deleteApiCall(
        Utils.EndPoint.deleteClientInfo(id),
        "application/json"
      );

      if (resData?.status === 200) {
        toast.success(resData?.data?.message, {
          autoClose: 2000,
        });
        return resData;
      } else {
        toast.error(resData?.data?.message, {
          autoClose: 2000,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.setItem("accessToken", "");
          window.location.reload();
        }
        toast.error(error.response.data?.message || "Please try again later", {
          autoClose: 2000,
        });
      } else {
        toast.error("Network error or no response from server", {
          autoClose: 2000,
        });
      }
    }
  };
};

//update client information
export const updateClientInfo = (id,value) => {
  return async (dispatch) => {
       try {
         const resData = await Utils.api.putApiCall(
           Utils.EndPoint.updateClientInfo(id),
           value,
           "application/json"
         );
   
         if (resData?.status === 201) {
           toast.success(resData?.data?.message, {
             autoClose: 2000,
           });
           return resData;
         } else {
           toast.error(resData?.data?.message, {
             autoClose: 2000,
           });
         }
       } catch (error) {
         if (error.response) {
           if (error.response.status === 401) {
             localStorage.setItem("accessToken", "");
             window.location.reload();
           }
           toast.error(error.response.data?.message || "Please try again later", {
             autoClose: 2000,
           });
         } else {
           toast.error("Network error or no response from server", {
             autoClose: 2000,
           });
         }
       }
     };

};

