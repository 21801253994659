import React, { useEffect, useState } from "react";
import {
  chatbotData,
} from "../../redux/action/chatbotAction";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import Header from "../../components/header";
import { useLocation, useNavigate } from "react-router";
import Loader from "../../components/Loader";
import ChatbotUpdate from "./chatbotUpdate";
import { createChatbotSession, getNextQuestion, getPrevQuestion, moveToCurrentQuestionFromDraft } from "../../redux/action/chatbotActions";
import { collectAllQuestionAndAnswer } from "./helper";
import './Chatbot.scss';

const ChatbotNewQuestions = () => {
  
  const isAuthenticated = localStorage?.getItem("accessToken");
  const intialMessagesState=[
    { id: 1, text: "For which client do you want to generate the text? ", sender: "bot" },
  ]
  
  const [currentSessionId, setCurrentSessionId] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  let [messages, setMessages] = useState([]);
  const [sortedPdfAnswers, setSortedPdfAnswers] = useState('');
  const [isPdfUploading, setPdfUploading] = useState(false);
  const [goingBack, setGoingBack] = useState(false);
  
  const currentQuestion = useSelector(
    (state) => state?.newChatbot?.currentQuestion
  );
  const selectedLanguage=useSelector((state)=>state.language.selectedLanguage);
  const profileInfo = useSelector(state => state?.profileReducer.personalInfoDetails);
  const sessionId = useSelector(state => state?.newChatbot?.newSession?._id);
  const userData = useSelector(state => state?.authenticationReducer);
  
  const location=useLocation();
  const d_sessionId=location?.state?.sessionId;
  const getDraftedSessionId=location.state?.sessionId;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  
  //Function to handle file upload and call file upload api
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    
    setPdfUploading(true);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL_FRONTEND}ai/getAnswersFromDocument/${sessionId}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${isAuthenticated}`,
        }
      }
    );
    
    if (response.ok) {
      const responseData = await response.json();
      setSortedPdfAnswers(responseData?.answer);
      setPdfUploading(false);
      return responseData;
    } else {
      return "Error uploading file.";
    }
  };

  //set sessionId state on change of sessionId coming from redux store
  useEffect(() => {
    setCurrentSessionId(sessionId);
  }, [sessionId])

  //Function that runs on selection of an option or typed text
  const handleOnSendMessage = async (file, msg) => {
    console.log("ms56g",msg);
    if(msg.trim()==""){
      return;
    }
  
    if (messages.length === 1 && !getDraftedSessionId && userData?.type==="agency") {
    const clientDescription=profileInfo?.clientInfo?.find((ele)=>ele?.name===msg)?.description;
      dispatch(createChatbotSession({
        "companyName": msg,
        "companyDescription": `${userData?.type==="agency"?clientDescription:'-'}`
      }));
      return;
    }
    
    let tempCurrentQuestion = {}
    if (file) {
     const fileUploadResponse=await uploadFile(file);
      tempCurrentQuestion = { ...currentQuestion }
      dispatch(getNextQuestion(tempCurrentQuestion, d_sessionId?d_sessionId:currentSessionId));
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages?.length + 1,
          text:fileUploadResponse?.question ,
          sender: "bot",
        },
      ]);
      return;
    }

    tempCurrentQuestion = { ...currentQuestion, answer: msg };

    if (msg === "Proceed Now") {
      handleQuestionPayload();
      return;
    }
   dispatch(getNextQuestion(tempCurrentQuestion, d_sessionId?d_sessionId:currentSessionId));
  };
  

 useEffect(() => {
  // if(!goingBack){
    if(messages?.filter(ele=>ele?.text===currentQuestion?.question)?.length===0 &&
    currentQuestion?.question!==undefined && currentQuestion?.question!=="")
    {
      console.log("entered if");
      let newMessages=[
        ...messages,         
        {
          id: messages?.length + 1,
          text: currentQuestion?.question,
          sender: "bot",
        },
      ]
      setMessages(newMessages);
    }   
  // }
   
  }, [currentQuestion])
 
  //Code in this useEffect handles setting the first question based on userType like agency,corporate or draftedSessionId on first render
 useEffect(()=>{
    if(!getDraftedSessionId && userData?.type==='agency'){
      setMessages(intialMessagesState);
   }
  else if(!getDraftedSessionId && userData?.type==='corporate'){
    dispatch(createChatbotSession({
      "companyName": profileInfo?.companyInfo?.name,
      "companyDescription": '-'
    }));
    setMessages([]);
   }
 
   else{
   const fetchCurrentQuestion = async () => {
      try {
        const res = await dispatch(moveToCurrentQuestionFromDraft(d_sessionId));
        console.log("r55es",res);
        const currentQuestionKey = res?.data?.currentQuestion;
    
        //Sort the initial session based on sequence key
         const sessionReceived=res?.data?.session;
          sessionReceived.sort((a,b)=>a.sequence-b.sequence)
         console.log("session654Received",sessionReceived);
          const currentQuestionIndexInSession = sessionReceived.findIndex(
          (ele) => ele.key === currentQuestionKey
        );
        
        const draftedCurrentQuestion= sessionReceived.filter(
          (ele) => ele.key === currentQuestionKey
        );
          let result;
          console.log(draftedCurrentQuestion)
        
          if(draftedCurrentQuestion?.length>0){
            const fiteredTillCurrentQuestion=sessionReceived.filter((ele,index)=>index<=currentQuestionIndexInSession);
            console.log("fiteredTill66CurrentQuestion",fiteredTillCurrentQuestion);
           result= collectAllQuestionAndAnswer(fiteredTillCurrentQuestion)
           if(currentQuestionKey==="start"){
            setMessages([{id:1,text:sessionReceived[0]?.question,sender:'bot'}]);
            console.log("res465ult",result);
         }
         else{
          console.log("res465ult",result);
           setMessages(result);
         }

          }
          else{
            setMessages([{id:1,text:sessionReceived[0]?.question,sender:'bot'}]);
          }
      } catch (error) {
        console.error("Error in fetching current question or updating state:", error);
      }
    };

    fetchCurrentQuestion();
  }
    
    },[])


 //Function for handling of moving to previous question onClicking the previous button
  const handleBack = () => {
    if (currentQuestion?.inputType.includes("select")) {
      setMessage("");
    }
    dispatch(getPrevQuestion(d_sessionId?d_sessionId:currentSessionId));

   setGoingBack(true);
    
  }

  useEffect(() => {
    if (goingBack) {
     console.log("check 1 mess465ages",messages)
     let questionMsgId = messages?.filter(x => x.text === currentQuestion?.question)[0]?.id;
     if(questionMsgId===undefined){
      return;
     }
     console.log("question465MsgId",messages?.filter(x => x.text === currentQuestion?.question));
    
      //  setMessages((prevMessages) => [
      //    ...prevMessages.filter(x => x?.id <= questionMsgId)
      //  ]);

      console.log("check 2" , questionMsgId , currentQuestion)


      let newArr   =  messages.filter((me)=>me.id <= questionMsgId)

      console.log("check 3" , newArr)

      setMessages(newArr)
 
      
      if((currentQuestion?.key!=="slogan" && currentQuestion?.answer!=="Skip") || (currentQuestion?.key==="slogan" && currentQuestion?.answer!=="Skip")){
        setMessage(currentQuestion?.answer);
       }

      setGoingBack(false);
    }
 
    if((currentQuestion?.key!=="slogan" && currentQuestion?.answer!=="Skip") || (currentQuestion?.key==="slogan" && currentQuestion?.answer!=="Skip")){
      setMessage(currentQuestion?.answer);
     }
 
  }, [currentQuestion?.question])

  //Function that handles creation of job vacancy by hitting the job vacancy creation
  const handleQuestionPayload = async() => {
    if (isAuthenticated === null) {
      navigate('/pricing');
      return;
    }
    
    const payload = { sessionId: d_sessionId?d_sessionId:sessionId,language:selectedLanguage }
    await dispatch(chatbotData(payload,setLoading,navigate));
    setMessages([]);
    navigate("/job/vacancies", { state: { loading } });
  };

  return (
    <>
      {isPdfUploading ? (
        <Loader isPdfUploading={isPdfUploading} />
      ) : (
        <>
          <Header />
          <Box>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="mb-40 mt-40"
              >
                <Typography
                  variant="h2"
                  className="fs-38 fw-600 text-center text-orange"
                >
                  Generate Job Vacancy Text
                </Typography>
                <Typography
                  variant="h2"
                  className="fs-16 fw-400 text-center m-auto"
                  sx={{ maxWidth: "720px" }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magn{" "}
                  {loading ? <CircularProgress /> : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ChatbotUpdate
                  onSendMessage={handleOnSendMessage}
                  messages={messages}
                  setMessages={setMessages}
                  currentQuestion={currentQuestion}
                  handleBack={handleBack}
                  message={message}
                  setMessage={setMessage}
                  sortedPdfAnswers={sortedPdfAnswers}
                  handleContinue={handleQuestionPayload}
                  getDraftedSessionId={getDraftedSessionId}
                  d_sessionId={d_sessionId}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default ChatbotNewQuestions;