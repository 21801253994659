import { Box, Button, CircularProgress, Dialog, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { MuiColorInput } from 'mui-color-input';
import Glider from 'glider-js';
import 'glider-js/glider.min.css';
import '../pages/socialmedia/socialMediaPopup.css';
import PlaceIcon from '@mui/icons-material/Place';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { saveAndShareAPI } from '../redux/action/chatbotAction';
import Utils from '../utils/reduxUtils';
import copysvg from "../assets/img/svg/copy.svg";
import { toast } from 'react-toastify';


const Socialmediapopup = ({ vacancyId, template, regenerate, open, onClose, uploadedImage, selectedImages, logo }) => {

  const { selectedColor } = useSelector((state) => state?.jobDescriptionFileUploadReducer)
  const [dowloadingImageAndFile, setDowloadingImageAndFile] = useState(false);
  const [value, setValue] = React.useState(selectedColor);
  const [activeIndex, setActiveIndex] = useState(0);
  const gliderRef = useRef(null);
  const dispatch = useDispatch()
  const [carouselLoading,setCarouselLoading]=useState(true)

 useEffect(() => {
    if (open) {
      setTimeout(() => {
        new Glider(gliderRef?.current, {
          slidesToShow: 1,
          scrollLock: true,
          draggable: true,
          dots: '#dots',
          arrows: {
            prev: '.glider-prev',
            next: '.glider-next'
          }
        });
        // Event listener to capture the active index when a slide becomes visible
        gliderRef.current.addEventListener('glider-slide-visible', function (event) {
          setActiveIndex(event.detail.slide);
        });
        setCarouselLoading(false);
         }, 10)

    }
  }, [gliderRef?.current]);

  let templateImages = [];
  if (uploadedImage != null) {
    templateImages = [...selectedImages, ...uploadedImage];
  }
  else {
    templateImages = [...selectedImages]
  }


  const handleColorChange = (newValue) => {
    setValue(newValue);
    dispatch({ type: Utils.ActionName.COLOR_SELECTION, payload: newValue });
  }

  const handleDownloadImage = async () => {
    setDowloadingImageAndFile(true);
    const slides = document.querySelectorAll('.combined-box');
    const zip = new JSZip();

    try {
      // Create an array of promises for image generation
      const imagePromises = Array.from(slides).map((slide, index) =>
        htmlToImage.toPng(slide, { backgroundColor: 'white' })
          .then((dataUrl) => {
            // Add each image to the ZIP file
            zip.file(`slide-${index + 1}.png`, dataURLtoBlob(dataUrl));
          })
      );
      const docBlob = await generateTextFile();
      zip.file(`captions.doc`, docBlob);

      // Wait for all image generation promises to resolve
      await Promise.all(imagePromises);

      // Generate the ZIP file
      zip.generateAsync({ type: 'blob' })
        .then((content) => {
          // Save the ZIP file
          saveAs(content, 'slides.zip');
          // generateAndDownloadTextFile();
          setDowloadingImageAndFile(false);
        });
    } catch (error) {
      toast.error(error)
    }
  };
  const generateTextFile = async () => {
    let textContent = '';

    // Loop through the template and build the content
    Object?.keys(template).forEach(key => {
      textContent += `Heading: ${key}\n`;
      textContent += `Content: ${template[key]}\n\n`;
    });

    // Create a new Blob with the text content
    const blob = new Blob([textContent], { type: 'text/plain' });
    return blob;
    // Create a link element, set it to point to the Blob URL

  };
  // Helper function to convert data URL to Blob
  const dataURLtoBlob = (dataUrl) => {
    const [header, data] = dataUrl.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const byteString = atob(data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: mime });
  };

  const generateAndDownloadTextFile = () => {
    let textContent = '';

    // Loop through the template and build the content
    Object?.keys(template).forEach(key => {
      textContent += `Heading: ${key}\n`;
      textContent += `Content: ${template[key]}\n\n`;
    });

    // Create a new Blob with the text content
    const blob = new Blob([textContent], { type: 'text/plain' });

    // Create a link element, set it to point to the Blob URL
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'LinkedIn_Posts.txt';  // Set the desired file name

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(link.href);
  };

  // Reusable function to open mail client with pre-filled data
  const sendMail = () => {
    const recipient = '';
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const baseUrl = url.origin;
    const subject = 'Check out this Social Media Template!';
    const body = `Hello, 
              
              Checkout this amazing social media template:
              
              ${baseUrl}/share/${vacancyId}
              
              Best regards,
              [Your Name]`;
    // Encode URI components to handle special characters
    const mailtoLink = `mailto:${encodeURIComponent(recipient)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the mailto link in a new window
    window.location.href = mailtoLink;
  };

const saveAndShare = async () => {
    try {
      const slides = document.querySelectorAll('.combined-box');
      const zip = new JSZip();
      const files = [];
      sendMail()
      // Create an array of promises for image generation
      const imagePromises = Array.from(slides).map((slide) =>
        htmlToImage.toPng(slide, { backgroundColor: 'white' }).then((dataUrl) => {
          files.push(dataURLtoBlob(dataUrl));
        })
      );
    
       // Wait for all image promises to resolve
      await Promise.all(imagePromises);

      const formData = new FormData();

      // Append each file to formData
      files.forEach((file) => {
        formData.append('files', file); // Adding each file with the key "files"
      });
      formData.append("vacancyId", vacancyId)

      // Now dispatch the action with formData and await it
      await dispatch(saveAndShareAPI(formData));
    } catch (error) {
      toast.error(error)
    }
  };

  //-- Copy Each Template--//
  const handleCopyPost1 = (postNumber) => {
    const result = Object.values(template);
    const response = result[postNumber - 1];
    navigator.clipboard.writeText(response)
      .then(() => { toast.success('Post Copied Successfully') })
      .catch(((err) => { toast.error(err) }));
  }

  return (
    // loading?<><p>Media loading</p></>:

    <Dialog onClose={onClose} open={open} className='startpopup socialmediastyle'>
      <CancelIcon className="close" onClick={onClose} />
      <Box >
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ background: "#FFE9D9" }}>
            <Box>
              <Box className='px-30'>
                <Box className='mx-auto mt-30 mb-30' sx={{ maxWidth: "440px" }}>
                  <Box className="glider-contain" style={{maxHeight:'550px'}}>
                    { carouselLoading ?<CircularProgress color='primary' size="30px" />:<></>}                  
                    <Box className="glider bg-white mainbx" sx={{visibility:carouselLoading?"hidden":"visible"}} ref={gliderRef}>
                      <Box className="">
                         <Box className='p-30' sx={{ position: 'relative' }}>
                          <Typography variant='h4' className='fs-10 fw-500 text-color-c2'>
                            {template['LinkedIn Post 1']}
                          </Typography>
                          <button
                            style={{
                              background: `url(${copysvg}) no-repeat center`,
                              position: 'absolute',
                              top: '10px',
                              right: '5px',
                              height: '22px',
                              width: '22px',
                              border: 'none',
                              cursor: "pointer",
                             
                            }} className='copybtnn'
                            onClick={() => handleCopyPost1(1)}
                          />

                        </Box>

                        <Box className='combined-box'>
                          <Box className='glider-img-box mt-auto'>
                            <Box className='layer d-flex' sx={{ background: value ? value : "rgb(12, 0, 129 , .6)" }}>
                              <Box className='m-auto px-30'>
                                <Typography variant='h4' className='fs-25 fw-700 text-white mb-20'>Vacature</Typography>
                                <Typography variant='h4' className='fs-15 fw-500 text-white mb-20'>{template?.Overlay_text[0]}</Typography>
                                <ul className='text-white no-before' style={{ padding: "0 0 0 15px" }}>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[1]} </li>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[2]}</li>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[3]} </li>
                                </ul>
                                <Typography variant='h4' className='fs-16 fw-500 text-orange'> <PlaceIcon className='text-white' />
                                  {template?.Overlay_text[4]?.length > 11 ? `${template?.Overlay_text[4]?.slice(0, 10)}...` : `${template?.Overlay_text[4]?.slice(0, 10)}`}</Typography>
                              </Box>
                            </Box>
                            {
                              logo &&
                              <img src={logo} crossOrigin="anonymous" className='logo' alt='logo'/>
                            }
                            <img className='main-img' crossOrigin="anonymous" src={templateImages[0]} alt='main-image'/>
                          </Box>

                        </Box>
                      </Box>
                      <Box>
                        <Box className='p-30' style={{ position: 'relative' }}>
                          <Typography variant='h4' className='fs-10 fw-500 text-color-c2 '>{template['LinkedIn Post 2']}</Typography>
                          <button
                            style={{
                              background: `url(${copysvg}) no-repeat center`,
                              top: '10px',
                              right: '5px',
                              height: '22px',
                              width: '22px',
                              border: 'none',
                              cursor: "pointer",
                              position: 'absolute'
                            }}
                            onClick={() => handleCopyPost1(2)}
                          />
                        </Box>
                        <Box className='combined-box mt-auto'>
                          <Box className='glider-img-box '>
                            <Box className='layer d-flex' sx={{ background: value ? value : "rgb(12, 0, 129 , .6)" }}>
                              <Box className='m-auto px-30'>
                                <Typography variant='h4' className='fs-25 fw-700 text-white mb-20'>Vacature</Typography>
                                <Typography variant='h4' className='fs-15 fw-500 text-white mb-20'>{template?.Overlay_text[0]}</Typography>
                                <ul className='text-white no-before' style={{ padding: "0 0 0 15px" }}>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[1]} </li>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[2]} </li>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[3]}</li>
                                </ul>
                                <Typography variant='h4' className='fs-16 fw-500 text-orange'> <PlaceIcon className='text-white' />  {template?.Overlay_text[4]?.length > 11 ? `${template?.Overlay_text[4]?.slice(0, 10)}...` : `${template?.Overlay_text[4]?.slice(0, 10)}`}</Typography>
                              </Box>
                            </Box>
                            {
                              logo && 
                            <img src={logo} crossOrigin="anonymous" className='logo' alt="logo"/>
                            }
                            <img className='main-img' crossOrigin="anonymous" src={templateImages[1]} alt='main-logo'/>
                          </Box>

                        </Box>
                      </Box>
                      <Box>
                        <Box className='p-30' style={{ position: 'relative' }}>
                          <Typography variant='h4' className='fs-10 fw-500 text-color-c2'>{template['LinkedIn Post 3']}</Typography>
                          <button
                            style={{
                              background: `url(${copysvg}) no-repeat center`,
                              top: '10px',
                              right: '5px',
                              height: '22px',
                              width: '22px',
                              border: 'none',
                              cursor: "pointer",
                              position: 'absolute'
                            }}
                            onClick={() => handleCopyPost1(3)}
                          />
                        </Box>
                        <Box className='combined-box mt-auto'>
                          <Box className='glider-img-box '>
                            <Box className='layer d-flex' sx={{ background: value ? value : "rgb(12, 0, 129 , .6)" }}>
                              <Box className='m-auto px-30'>
                                <Typography variant='h4' className='fs-25 fw-700 text-white mb-20'>Vacature</Typography>
                                <Typography variant='h4' className='fs-15 fw-500 text-white mb-20'>{template?.Overlay_text[0]}</Typography>
                                <ul className='text-white no-before' style={{ padding: "0 0 0 15px" }}>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[1]} </li>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[2]} </li>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[3]}</li>
                                </ul>
                                <Typography variant='h4' className='fs-16 fw-500 text-orange'> <PlaceIcon className='text-white' />  {template?.Overlay_text[4]?.length > 11 ? `${template?.Overlay_text[4]?.slice(0, 10)}...` : `${template?.Overlay_text[4]?.slice(0, 10)}`}</Typography>
                              </Box>
                            </Box>
                            {
                              logo &&
                              <img src={logo} crossOrigin="anonymous" className='logo' alt='logo'/>
                            }
                            <img className='main-img' crossOrigin="anonymous" src={templateImages[2]} alt='main-image'/>
                          </Box>

                        </Box>
                      </Box>
                      <Box>
                        <Box className='p-30' style={{ position: 'relative' }}>

                          <Typography variant='h4' className='fs-10 fw-500 text-color-c2'>{template['LinkedIn Post 4']}</Typography>
                          <button
                            style={{
                              background: `url(${copysvg}) no-repeat center`,
                              top: '10px',
                              right: '5px',
                              height: '22px',
                              width: '22px',
                              border: 'none',
                              cursor: "pointer",
                              position: 'absolute', // Positioning the button absolutely
                            }}
                            onClick={() => handleCopyPost1(4)}
                          />


                        </Box>
                        <Box className='combined-box mt-auto'>
                          <Box className='glider-img-box '>
                            <Box className='layer d-flex' sx={{ background: value ? value : "rgb(12, 0, 129 , .6)" }}>
                              <Box className='m-auto px-30'>
                                <Typography variant='h4' className='fs-25 fw-700 text-white mb-20'>Vacature</Typography>
                                <Typography variant='h4' className='fs-15 fw-500 text-white mb-20'>{template?.Overlay_text[0]}</Typography>
                                <ul className='text-white no-before' style={{ padding: "0 0 0 15px" }}>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[1]} </li>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[2]} </li>
                                  <li className='fs-12 fw-600'>{template?.Overlay_text[3]}</li>
                                </ul>
                                <Typography variant='h4' className='fs-16 fw-500 text-orange'> <PlaceIcon className='text-white' />  {template?.Overlay_text[4]?.length > 11 ? `${template?.Overlay_text[4]?.slice(0, 10)}...` : `${template?.Overlay_text[4]?.slice(0, 10)}`}</Typography>
                              </Box>
                            </Box>
                            {
                              logo &&
                              <img src={logo} crossOrigin="anonymous" className='logo' alt='logo'/>
                            }
                            <img className='main-img' crossOrigin="anonymous" src={templateImages[3]} alt='main-image'/>

                          </Box>

                        </Box>

                      </Box>
                    </Box>
                    
                    <Box className='glider-btn-box'>
                      <button aria-label="Previous" className="glider-prev"><KeyboardArrowLeftIcon /></button>
                      <Divider className='mb-20' sx={{ border: "2px solid #DEDEDE !important", borderColor: "#DEDEDE !important" }}></Divider>
                      <button aria-label="Next" className="glider-next"><KeyboardArrowRightIcon /></button>
                    </Box>
                    <Box className='glider-btn-box number'>
                      <button aria-label="Previous" className="glider-prev"> {activeIndex + 1} </button>
                      <Divider className='mb-20' sx={{ border: "2px solid #DEDEDE !important", borderColor: "#DEDEDE !important" }}></Divider>
                      <button aria-label="Next" className="glider-next">4</button>
                    </Box>
                    <Box role="tablist" id="dots" className="dots"></Box>
                  </Box>
                </Box>

              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className='d-flex socialleft'>
            <Box className='m-auto p-30 '>
              <Typography variant='h4' className='fs-18 fw-600 text-color-c1 mb-20'>Color Selection </Typography>
              <Box className='fs-18 fw-600 d-flex gap-20 mb-20 bluecirl' >
                <MuiColorInput value={value} onChange={handleColorChange}/>

              </Box>
              <Divider className='mb-20' sx={{ border: "1.5px solid #DEDEDE !important" }}></Divider>
              <Box className="gap-15 flex-column">
                <Button onClick={regenerate} className='secondary-btn'>Regenerate Template</Button>
                <Button onClick={() => saveAndShare()} className='secondary-btn'>Save & Share</Button>
                <Button onClick={generateAndDownloadTextFile} className='secondary-btn'>Download Social Media</Button>
                {dowloadingImageAndFile ? <Button className='secondary-btn'><CircularProgress sx={{ color: "#F89B53" }} size={24} /></Button> :
                  <Button onClick={handleDownloadImage} sx={{ fontSize: "13px" }} className='secondary-btn'>Download Both Template</Button>}
              </Box>
            </Box>
          </Grid>
        </Grid> 
      </Box>
    </Dialog>

  )
}

export default Socialmediapopup
