import { toast } from "react-toastify";
import Utils from "../../utils/reduxUtils";
import { getProfileInfo } from "./profileAction";


// export const profileImageUpload = (id, value) => {
//     return async (dispatch) => {

//       try {
//         const resData = await Utils.api.patchApiCall(
//           Utils.EndPoint.updateProfileImage(id),
//           value,
//           "multipart/form-data"
//         );
  
//         if (resData?.status === 201) {
//           toast.success(resData?.data?.message, {
//             autoClose: 2000,
//           });
//           dispatch({ type: Utils.ActionName.UPLOAD_IMAGE_SUCCESS, payload: resData.data?.profileImageUrl });
//           dispatch(getProfileInfo(id));

//           return resData;
//         } 
//         else {
//           toast.error(resData?.data?.message, {
//             autoClose: 2000,
//           });
//         }
//       } catch (error) {
//         if (error.response) {
//           if (error.response.status === 401) {
//             localStorage.setItem("accessToken", "");
//             window.location.reload();
//           }
//           toast.error(error.response.data?.message || "Please try again later", {
//             autoClose: 2000,
//           });
//         } else {
//           toast.error("Network error or no response from server", {
//             autoClose: 2000,
//           });
//         }
//       }
//     };
//   };
  



export const profileImageUpload = (id, value) => {
  return async (dispatch) => {
    try {
      // Calling patchApiCall with the expected parameters
      Utils.api.patchApiCall(
        Utils.EndPoint.updateProfileImage(id), // API endpoint
        value, // Body (FormData)
        (resData) => {
          // Success Callback
          if (resData?.status === 201) {
            toast.success(resData?.data?.message, {
              autoClose: 2000,
            });

            dispatch({
              type: Utils.ActionName.UPLOAD_IMAGE_SUCCESS,
              payload: resData?.data?.data.profileImageUrl,
            });

            dispatch(getProfileInfo(id));
          } else {
            toast.error(resData?.data?.message, {
              autoClose: 2000,
            });
          }
        },
        (error) => {
          // Error Callback
          if (error?.data?.statusCode === 408) {
            localStorage.setItem("accessToken", "");
            window.location.reload();
          }

          toast.error(error?.data?.message || "Please try again later", {
            autoClose: 2000,
          });
        },
        false, // IsArrayBuffer (set to false)
        "multi" // headerType (should be "multi" for multipart/form-data)
      );
    } catch (error) {
      toast.error("Unexpected error occurred", {
        autoClose: 2000,
      });
    }
  };
};
